<template>
	<div class="footer">
		<div class="footer-contain">
			<div class="footer-content">
				<el-row>
					<el-col :span="14">
						<div class="footer-text">
							<!-- <img class="footlogo" src="@/assets/img/footlogo.png" alt="" /> -->
							<img class="tel" src="@/assets/img/telw.png" alt="" />
							<span class="text">联系电话：0576-85159089 85159028</span>
							<span style="font-size: 10px;">未经书面授权严禁转载本站任何信息</span>
						</div>
						<div class="footer-text mt">
							<!-- <span></span> -->
							<!-- <span>版权所有</span> -->
							
							<span>Copyright © lhrc.com.cn</span>
						</div>
						<div class="footer-text mt">
							<span>地址：浙江省台州市临海市总部经济商务区河阳路319号</span>
							<span><a style="color: #898989;" href="https://beian.miit.gov.cn"
									target="_blank">浙ICP备09030839号-1</a></span>
						</div>
						<div  class="footer-text mt">
							<a href="https://v6.51.la/report/overview?comId=506902" target="_blank" style="text-decoration: none;font-weight: bold;">流量统计</a>
						</div>
						<!-- <div class="footer-text mt">
							<img src="@/assets/img/ba.png" alt="" /><span>浙公网安备
								3307810210013号</span><span>网站标识码：3307810037</span>
						</div> -->
					</el-col>
					<el-col :span="10">
						<div class="logo-box">
							<img :src="require('@/assets/img/home/logo_pic.png')" class="logo_pic">
							<img :src="require('@/assets/img/home/logo_text.png')" class="logo_text">
						</div>
						<div class="footer-net">
							<!-- <div class="footer-net-list">
								<el-image fit="cover" class="img" :src="require('@/assets/img/ypdy.png')"
									:preview-src-list="[require('@/assets/img/ypdy.png')]" alt=""></el-image>
								<div class="net-name">微信公众号</div>
							</div>
							<div class="footer-net-list">
								<el-image fit="cover" class="img" :src="require('@/assets/img/yydy.png')"
									:preview-src-list="[require('@/assets/img/yydy.png')]" alt=""></el-image>
								<div class="net-name">微信小程序</div>
							</div> -->
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		GetAdvLinkByType,
		GetTextLinkList
	} from "@/api/home";
	export default {
		data() {
			return {
				selectData: [],
			};
		},
		created() {
			this.GetTextLinkList();
		},
		methods: {
			GetTextLinkList(type) {
				var _this = this;
				var par = {
					id: type,
				};
				GetTextLinkList(par).then((res) => {
					if (res.success) {
						var rdata = res.data;
						_this.selectData = rdata;
						rdata.forEach((item, i) => {
							item.value = "";
							this.GetAdvLinkByType(item.id, i);
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			GetAdvLinkByType(type, i) {
				var _this = this;
				var par = {
					id: type,
				};
				GetAdvLinkByType(par).then((res) => {
					if (res.success) {
						_this.selectData[i].options = res.data;
						_this.selectData = JSON.parse(JSON.stringify(_this.selectData));
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			changesel(v) {
				this.resolvewin(v, {}, 6);
			},
		},
	};
</script>


<style lang="scss" scoped>
.logo-box{
	float: right;
	text-align: center;
	img{
		display: block;
		margin: 0 auto;
	}
	.logo_pic{
		margin-top: 5px;
		margin-bottom: 10px;
	}
}
	.footer {
		width: 100%;
		margin: 0px;
		padding: 10px 0;
		background: #333;
		position: relative;

		.footer-contain {
			width: 1200px;
			margin: 0px auto;

			.mt {
				margin-top: 2px;

				span {
					color: #898989;
				}

			}

			.mt a:link,
			.mt a:visited {
				color: #898989;

			}

			.footer-content {
				// padding-top: 20px;

				.footer-text {
					font-size: 14px;
					color: #fff;
					text-align: left;
					display: flex;
					align-items: center;
					line-height: 25px;

					.footlogo {
						height: 40px;
						margin-right: 10px;
					}

					.tel {
						width: 40px;
						height: 40px;
						margin-right: 10px;
					}

					img {
						margin-right: 4px;
					}

					.text {
						font-size: 20px;
						font-weight: bold;
					}

					span {
						margin-right: 14px;

						a {
							color: #fff;
							text-decoration: none;
						}

						a:hover {
							color: #0055ff;
							text-decoration: underline
						}
					}
				}

				.footer-net {
					display: flex;
					justify-content: flex-end;
					align-items: center;

					.footer-net-list {
						display: flex;
						flex-direction: column;
						text-align: center;
						margin-left: 12px;
						color: #fff;
						font-size: 14px;

						.net-name {
							color: #898989;
						}

						.img {
							width: 98px;
							height: 98px;
							margin-bottom: 5px;
							background: #ffffff;
							padding: 4px;
							border-radius: 4px;
						}

					}
				}
			}
		}
	}
</style>
